/*==============================
=          GLOBAL  JS          =
==============================*/
import $ from 'jquery';
import 'jquery-modal';
import 'slick-slider';

$(function(){
/* slicks */
	$('.topslide').slick({
	autoplay: true,
	autoplaySpeed: 5000,
	arrows: false
	});

	$('.infoslide').slick({
	autoplay: false,
	arrows: true,
	prevArrow:'<button type="button" class="arrow previous">&raquo;</button>',
	nextArrow:'<button type="button" class="arrow next">&laquo;</button>',
	dots: false,
	slidesToShow: 2,
	slidesToScroll: 2,
	responsive: [
	    {
	      breakpoint: 780,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1
	      }
	    }
	  ]
	});
	$('.beforeAfter__slides').slick({
		autoplay: false,
		arrows: true,
		prevArrow:'<button type="button" class="arrow previous">&raquo;</button>',
		nextArrow:'<button type="button" class="arrow next">&laquo;</button>',
		dots: true,
		appendDots: $('.beforeAfter__dots'),
	});

/*! Pushy - v0.9.2 - 2014-9-13
* Pushy is a responsive off-canvas navigation menu using CSS transforms & transitions.
* https://github.com/christophery/pushy/
* by Christopher Yee */

	$(function() {
		var pushy = $('.pushy'), //menu css class
			body = $('body'),
			container = $('#container'), //container css class
			push = $('.push'), //css class to add pushy capability
			siteOverlay = $('.site-overlay'), //site overlay
			pushyClass = "pushy-left pushy-open", //menu position & menu open class
			pushyActiveClass = "pushy-active", //css class to toggle site overlay
			containerClass = "container-push", //container open class
			pushClass = "push-push", //css class to add pushy capability
			menuBtn = $('.menu-btn, .pushy a'), //css classes to toggle the menu
			menuSpeed = 200, //jQuery fallback menu speed
			menuWidth = pushy.width() + "px"; //jQuery fallback menu width

		function togglePushy(){
			body.toggleClass(pushyActiveClass); //toggle site overlay
			pushy.toggleClass(pushyClass);
			container.toggleClass(containerClass);
			push.toggleClass(pushClass); //css class to add pushy capability
		}

		function openPushyFallback(){
			body.addClass(pushyActiveClass);
			pushy.animate({left: "0px"}, menuSpeed);
			container.animate({left: menuWidth}, menuSpeed);
			push.animate({left: menuWidth}, menuSpeed); //css class to add pushy capability
		}

		function closePushyFallback(){
			body.removeClass(pushyActiveClass);
			pushy.animate({left: "-" + menuWidth}, menuSpeed);
			container.animate({left: "0px"}, menuSpeed);
			push.animate({left: "0px"}, menuSpeed); //css class to add pushy capability
		}

		/*checks if 3d transforms are supported removing the modernizr dependency*/
		var cssTransforms3d = (function csstransforms3d(){
			var el = document.createElement('p'),
			supported = false,
			transforms = {
			    'webkitTransform':'-webkit-transform',
			    'OTransform':'-o-transform',
			    'msTransform':'-ms-transform',
			    'MozTransform':'-moz-transform',
			    'transform':'transform'
			};

			// Add it to the body to get the computed style
			document.body.insertBefore(el, null);

			for(var t in transforms){
			    if( el.style[t] !== undefined ){
			        el.style[t] = 'translate3d(1px,1px,1px)';
			        supported = window.getComputedStyle(el).getPropertyValue(transforms[t]);
			    }
			}

			document.body.removeChild(el);

			return (supported !== undefined && supported.length > 0 && supported !== "none");
		})();

		if(cssTransforms3d){
			//toggle menu
			menuBtn.click(function() {
				togglePushy();
			});
			//close menu when clicking site overlay
			siteOverlay.click(function(){
				togglePushy();
			});
		}else{
			//jQuery fallback
			pushy.css({left: "-" + menuWidth}); //hide menu by default
			container.css({"overflow-x": "hidden"}); //fixes IE scrollbar issue

			//keep track of menu state (open/close)
			var state = true;

			//toggle menu
			menuBtn.click(function() {
				if (state) {
					openPushyFallback();
					state = false;
				} else {
					closePushyFallback();
					state = true;
				}
			});

			//close menu when clicking site overlay
			siteOverlay.click(function(){
				if (state) {
					openPushyFallback();
					state = false;
				} else {
					closePushyFallback();
					state = true;
				}
			});
		}
	});

//Simple Dropdown
	$(function(){

	    $("ul.dropdown li").hover(function(){

	        $(this).addClass("hover");
	        $('ul:first',this).css('visibility', 'visible');

	    }, function(){

	        $(this).removeClass("hover");
	        $('ul:first',this).css('visibility', 'hidden');

	    });

	    $("ul.dropdown li ul li:has(ul)").find("a:first").append(" &raquo; ");

	});

	(function($){
		/* hoverIntent by Brian Cherne */
		$.fn.hoverIntent = function(f,g) {
			// default configuration options
			var cfg = {
				sensitivity: 7,
				interval: 100,
				timeout: 0
			};
			// override configuration options with user supplied object
			cfg = $.extend(cfg, g ? { over: f, out: g } : f );

			// instantiate variables
			// cX, cY = current X and Y position of mouse, updated by mousemove event
			// pX, pY = previous X and Y position of mouse, set by mouseover and polling interval
			var cX, cY, pX, pY;

			// A private function for getting mouse position
			var track = function(ev) {
				cX = ev.pageX;
				cY = ev.pageY;
			};

			// A private function for comparing current and previous mouse position
			var compare = function(ev,ob) {
				ob.hoverIntent_t = clearTimeout(ob.hoverIntent_t);
				// compare mouse positions to see if they've crossed the threshold
				if ( ( Math.abs(pX-cX) + Math.abs(pY-cY) ) < cfg.sensitivity ) {
					$(ob).unbind("mousemove",track);
					// set hoverIntent state to true (so mouseOut can be called)
					ob.hoverIntent_s = 1;
					return cfg.over.apply(ob,[ev]);
				} else {
					// set previous coordinates for next time
					pX = cX; pY = cY;
					// use self-calling timeout, guarantees intervals are spaced out properly (avoids JavaScript timer bugs)
					ob.hoverIntent_t = setTimeout( function(){compare(ev, ob);} , cfg.interval );
				}
			};

			// A private function for delaying the mouseOut function
			var delay = function(ev,ob) {
				ob.hoverIntent_t = clearTimeout(ob.hoverIntent_t);
				ob.hoverIntent_s = 0;
				return cfg.out.apply(ob,[ev]);
			};

			// A private function for handling mouse 'hovering'
			var handleHover = function(e) {
				// next three lines copied from jQuery.hover, ignore children onMouseOver/onMouseOut
				var p = (e.type == "mouseover" ? e.fromElement : e.toElement) || e.relatedTarget;
				while ( p && p != this ) { try { p = p.parentNode; } catch(e) { p = this; } }
				if ( p == this ) { return false; }

				// copy objects to be passed into t (required for event object to be passed in IE)
				//var ev = jQuery.extend({},e);
				var ob = this;

				// cancel hoverIntent timer if it exists
				if (ob.hoverIntent_t) { ob.hoverIntent_t = clearTimeout(ob.hoverIntent_t); }

				// else e.type == "onmouseover"
				if (e.type == "mouseover") {
					// set "previous" X and Y position based on initial entry point
					pX = ev.pageX; pY = ev.pageY;
					// update "current" X and Y position based on mousemove
					$(ob).bind("mousemove",track);
					// start polling interval (self-calling timeout) to compare mouse coordinates over time
					if (ob.hoverIntent_s != 1) { ob.hoverIntent_t = setTimeout( function(){compare(ev,ob);} , cfg.interval );}

				// else e.type == "onmouseout"
				} else {
					// unbind expensive mousemove event
					$(ob).unbind("mousemove",track);
					// if hoverIntent state is true, then call the mouseOut function after the specified delay
					if (ob.hoverIntent_s == 1) { ob.hoverIntent_t = setTimeout( function(){delay(ev,ob);} , cfg.timeout );}
				}
			};

			// bind the function to the two event listeners
			return this.mouseover(handleHover).mouseout(handleHover);
		};

	});
	//end Simple Dropdown

	/* beforeafter hovers */
	$('.beforeAfter__pair').hover( function(){
		$(this).find('.beforeAfter__fullFaceImage, .beforeAfter__slideText').css('opacity', '1.0');
	},
	function(){
		$(this).find('.beforeAfter__fullFaceImage, .beforeAfter__slideText').css('opacity', '0.0');
	});
});
